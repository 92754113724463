import _path from "path";
import _querystring from "querystring";
import _url from "url";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
*  URI Parser, based on Rubys Addressable (http://addressable.rubyforge.org/api/) 
*  
*  and http://labs.apache.org/webarch/uri/rfc/rfc3986.html
*  
*  URI#extract method is http://daringfireball.net/2010/07/improved_regex_for_matching_urls
*/
function URI() {
  (this || _global).href = null;
  (this || _global).scheme = null;
  (this || _global).userinfo = null;
  (this || _global).username = null;
  (this || _global).password = null;
  (this || _global).host = null;
  (this || _global).port = null;
  (this || _global).pathname = "";
  (this || _global).querystring = null;
  (this || _global).fragment = null;

  (this || _global).isAbsolute = function () {
    return !this.isRelative();
  };

  (this || _global).isRelative = function () {
    return (!(this || _global).scheme || (this || _global).scheme == "file") && (this || _global).pathname.charAt() != "/";
  };

  (this || _global).normalize = function () {
    // Some schemes require the pathname to have /
    if (((this || _global).pathname == "" || (this || _global).pathname.charAt() != "/") && PATH_SLASH_SCHEMES.indexOf((this || _global).scheme) > -1) (this || _global).pathname = "/" + (this || _global).pathname; // We'll assume there's no host if the scheme is undefined or file

    if ((this || _global).host && (!(this || _global).scheme || (this || _global).scheme == "file")) {
      (this || _global).pathname = (this || _global).host + (this || _global).pathname;
      (this || _global).authority = "";
    } // Normalizes /a/../b paths to /b and /a/b/./c to /a/b/c


    (this || _global).pathname = _path.normalize((this || _global).pathname);
  };

  (this || _global).toString = function () {
    var res = "";
    if ((this || _global).scheme) res += (this || _global).scheme + ":";
    if ((this || _global).authority) res += "//" + (this || _global).authority;else if ((this || _global).scheme == "file") res += "//";
    res += (this || _global).path;
    if ((this || _global).fragment) res += "#" + (this || _global).fragment;
    return res;
  };

  this.__defineGetter__("authority", function () {
    var authority = "";
    if ((this || _global).userinfo) authority += (this || _global).userinfo + "@";
    authority += (this || _global).host || "";
    if ((this || _global).port && (this || _global).port !== KNOWN_PORTS[(this || _global).scheme]) authority += ":" + (this || _global).port;
    return authority.length ? authority : null;
  });

  this.__defineSetter__("authority", function (str) {
    var md = RE_AUTHORITY.exec(str) || [];
    (this || _global).userinfo = md[1];
    (this || _global).host = md[2];
    (this || _global).port = md[4] || KNOWN_PORTS[(this || _global).scheme];
  });

  this.__defineGetter__("userinfo", function () {
    var info = (this || _global).username || "";
    if ((this || _global).password) info += ":" + (this || _global).password;
    return info || null;
  });

  this.__defineSetter__("userinfo", function (info) {
    if (info) {
      info = info.replace(/@$/, "").split(":");
      (this || _global).username = info[0];
      (this || _global).password = info[1];
    }
  });

  this.__defineGetter__("query", function () {
    return _querystring.parse((this || _global).querystring);
  });

  this.__defineSetter__("query", function (qs) {
    (this || _global).querystring = _querystring.stringify(qs);
  });

  this.__defineGetter__("search", function () {
    return ((this || _global).querystring || "").length ? "?" + (this || _global).querystring : "";
  });

  this.__defineGetter__("path", function () {
    return (this || _global).pathname + (this || _global).search;
  });

  this.__defineSetter__("path", function (path) {
    var i = path.indexOf("?");
    (this || _global).pathname = path.slice(0, i);
    (this || _global).querystring = path.slice(i + 1);
  });
}

var PATH_SLASH_SCHEMES = ["http", "https", "ftp", "tftp"];
var RE_URI = /^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
var RE_AUTHORITY = /^([^@]+@)?([^:]+)(:(\d+))?$/;
var RE_EXTRACT_URL = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
var KNOWN_PORTS = {
  "http": 80,
  "https": 443,
  "ftp": 21,
  "ssh": 22
};
/**
*  Parses a String into an URI object. 
*  
*  Defaults to normalizing the URI, pass `false` as the second argument to skip.
*  
*  Example:
*   require("addressable").parse( "http://www.example.com/path/to/file.html?query=1&and=2#three" )
*  
*  @param {URI|String} href   The uri to parse into an URI.
*  @return An URI object. Or null if the href is not an parsable uri.
*/

URI.parse = function (href) {
  href = URI.format(href);
  var md = RE_URI.exec(href);

  if (md) {
    var uri = new URI();
    uri.href = href;
    uri.scheme = md[2];
    uri.authority = md[4] || "";
    uri.pathname = md[5];
    uri.querystring = md[7];
    uri.fragment = md[9];
    if (arguments[1] !== false) uri.normalize();
    return uri;
  }

  return null;
};
/**
*  Extracts URIs from a text and returns an array of URI objects or replaces them with the return of a callback function.
*  
*  @param {String} text   The text from which URIs should be extracted.
*  @param {Function} fn   (optional) If set the returns of the function will replace the url it matches. The function receives the matched URI object as the argument.
*  @return {Array|String} An array of URIs if no callback function is passed. Otherwise the resulting text with replaces URIs.
*/


URI.extract = function (text, fn) {
  if (typeof fn == "function") return text.replace(RE_EXTRACT_URL, function (url) {
    return fn.call(this || _global, URI.parse(url)) || url;
  });
  var uris = [],
      md;

  while (md = RE_EXTRACT_URL.exec(text)) uris.push(URI.parse(md[0]));

  return uris;
};
/**
*  Converts an addressable URI to a string.
*  (added for compability with the URL module)
*  
*  @param {URI} The Addressable URI object to convert into a string.
*  @return A built string of the URI.
*/


URI.format = function (uri) {
  if (uri instanceof URI) return uri.toString();
  if (typeof uri === "object") return _url.format(uri);
  return uri;
};

exports.URI = URI;
exports.parse = URI.parse;
exports.format = URI.format;
exports.stringify = URI.format;
exports.extract = URI.extract;
export default exports;
export const parse = exports.parse,
      format = exports.format,
      stringify = exports.stringify,
      extract = exports.extract;
const _URI = exports.URI;
export { _URI as URI };